import { render, staticRenderFns } from "./drawsDetails.vue?vue&type=template&id=ed2dcc0c&scoped=true&"
import script from "./drawsDetails.vue?vue&type=script&lang=js&"
export * from "./drawsDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed2dcc0c",
  null
  
)

export default component.exports