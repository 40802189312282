<template>
	<el-drawer title="抽盒详情" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
		:modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
		<div class="h-full flex flex-dc" v-if="isDrawer">
			<div class="drawer_title pall-30 flex flex-jb flex-n">
				<div class="fs-18 fw-bold">抽盒详情</div>
				<i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
			</div>
			<div class="flex-1 overflow-scroll-y">
				<t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="4" @handleEvent="handleEvent"
					class="drawer_form">
					<!-- 平台自定义插槽 -->
					<template #detailsList>
						<div class="pos-s t-0 bgc-fff zi-10">
							<div class="pb-20 pl-40 flex flex-ac flex-jb">
								<div class="fs-14 fw-bold flex-1">
									<span class="h-10 w-5 bgc-38393b mr-10 dis-in-block"></span>
									<span>系列商品</span>
								</div>
								<div class="flex flex-ac">
									<div class="mr-30">A级：{{aCount}}个；B级：{{bCount}}个；C级：{{cCount}}个</div>
									<div class="mr-30">数量：{{formOpts.formData.detailsList.length}}</div>
									<el-button type="primary" @click="addDomain">新增</el-button>
								</div>
							</div>
						</div>
						<div v-for="(domains,index) in formOpts.formData.detailsList" class="flex flex-ac mb-20" :key="index">
							<el-form-item :label="'商品名称'" label-position="right" :prop="'detailsList.' + index + '.ductName'"
								:rules="{required: true, message: '商品名称不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.ductName"></el-input>
							</el-form-item>
							<el-form-item label="商品价格" label-position="right" :prop="'detailsList.' + index + '.price'"
								:rules="{required: true, message: '商品价格不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.price"></el-input>
							</el-form-item>
							<el-form-item label="商品等级" label-position="right" :prop="'detailsList.' + index + '.levelType'"
								:rules="{required: true, message: '请至少选择一个商品等级', trigger: 'change'}" class="flex flex-ac"
								v-if="!testFlag">
								<el-select v-model="domains.levelType" clearable placeholder="请选择">
									<el-option v-for="item in formOpts.listTypeInfo.levelTypes" :key="item.value" :label="item.key"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="商品图片" label-position="right" :prop="'detailsList.' + index + '.boxImg'"
								:rules="{required: true, message: '图片不能为空', trigger: 'blur'}" class="flex flex-ac">
								<upload-file upStyle="width: 50px; height: 50px; border-radius: 5px; overflow: hidden;"
									iconName="el-icon-upload" iconSize="16" v-model="domains.boxImg"></upload-file>
							</el-form-item>
							<el-button type="primary" plain @click.prevent="removeDomain(index)" class="ml-20"
								v-if="formOpts.formData.detailsList.length > 1">删除 </el-button>
						</div>
					</template>
					<!-- 上传图片 -->
					<template #uploadFile="data">
						<upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
							iconName="el-icon-upload" iconSize="26" v-model="formOpts.formData[data.value]"></upload-file>
					</template>
				</t-simple-form>
			</div>
			<div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
				<el-button type="primary" @click="submitForm" :loading="butLoading">{{goodId?'保存':'提交'}}</el-button>
				<el-button type="primary" plain @click="handleClose">取消</el-button>
			</div>
		</div>
		<el-dialog title="提示" :visible.sync="dialogVisible" close="cancel" width="30%" append-to-body>
			<span>您确认展示{{formOpts.formData.showPage}}套吗？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="editOkSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</el-drawer>
</template>
<script>
	export default {
		name: 'drawsDetails',
		components: {
			"TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		data() {
			return {
				dialogVisible: false, //确认展示套数，提示弹框
				goodId: '', //详情id
				isDrawer: false,
				butLoading: false,
				formOpts: {
					labelPosition: 'top',
					ref: null,
					formData: {
						// seriesCode: '',	//系列编码
						seriesName: '', //系列名称
						seriesType: '', //系列分类
						shelves: '', //是否上架
						price: '', //价格
						soldPrice: '', //折扣价
						boxNum: '', //盒子数量
						deliverDate: '', //发货日期
						totalPage: '10', //总的套数
						showPage: '10', //展示的套数
						lucencysum: '1', //透视卡使用次数	
						tipsum: '3', //提示卡使用次数
						luckyNum: '3', //连抽次数	
						asum: '0', //A类可抽次数
						bsum: '10', //B类可中次数
						isRapidBuyB:1,//立刻购买B类开关
						integral: '0', //可兑换的积分
						recycleBlance: '0', //回收金额
						maxPage: 3000, //限制套数
						consume: '',
						coverImg: '', //封面图
						boxImg: '', //盒子图片
						hdImg: '', //高清图片
						entireImg: '', //全家福图片
						detailImg: '', //详情图
						id: '', //系列id
						note: '', //玩法说明
						robotDate: '5', //	机器人抢盒时间
						sort: '', //排序
						detailsList: [{
							ductName: '',
							price: '',
							levelType: '',
							boxImg: '',
						}], //商品信息
					},
					fieldList: [
						// { label: '系列编号', value: 'seriesCode', comp: 'el-input' },
						{
							label: '系列名称',
							value: 'seriesName',
							comp: 'el-input'
						}, {
							label: '分类',
							value: 'seriesType',
							type: 'select-arr',
							comp: 'el-select',
							list: 'seriesTypes',
							bind: {
								disabled: false
							},
							arrLabel: 'key',
							arrKey: 'value'
						}, {
							label: '上下架售罄',
							value: 'shelves',
							type: 'select-arr',
							comp: 'el-select',
							list: 'shelves',
							arrLabel: 'key',
							arrKey: 'value'
						}, {
							label: '发货日期',
							value: 'deliverDate',
							type: 'date',
							comp: 'el-date-picker',
							bind: {
								format: "yyyy-MM-dd",
								valueFormat: "yyyy-MM-dd"
							}
						}, {
							label: '原价',
							value: 'price',
							type: 'number',
							comp: 'el-input'
						}, {
							label: '售出价',
							value: 'soldPrice',
							type: 'number',
							comp: 'el-input',
							event: 'showPrice'
						}, {
							label: '连抽次数',
							value: 'luckyNum',
							type: 'number',
							comp: 'el-input'
						}, {
							label: '机器人时间',
							value: 'robotDate',
							type: 'number',
							comp: 'el-input',
							isHideItem: () => {
								return !this.testFlag
							}
						}, {
							label: '回收积分',
							value: 'integral',
							type: 'number',
							comp: 'el-input',
							isHideItem: () => {
								return !this.testFlag
							}
						}, {
							label: '回收鸭币',
							value: 'recycleBlance',
							type: 'number',
							comp: 'el-input',
							isHideItem: () => {
								return !this.testFlag
							}
						}, {
							label: '总的套数',
							value: 'totalPage',
							type: 'number',
							comp: 'el-input',
							bind: {
								disabled: true
							},
						}, {
							label: '展示的套数',
							value: 'showPage',
							type: 'number',
							comp: 'el-input',
							event: 'showPage'
						}, {
							label: '透视卡次数',
							value: 'lucencysum',
							type: 'number',
							comp: 'el-input'
						}, {
							label: '提示卡次数',
							value: 'tipsum',
							type: 'number',
							comp: 'el-input'
						}, {
							label: 'A类可中次数',
							value: 'asum',
							type: 'number',
							comp: 'el-input',
							isHideItem: () => {
								return !this.testFlag
							}
						}, {
							label: 'B类几次可中',
							value: 'bsum',
							type: 'number',
							comp: 'el-input',
							isHideItem: () => {
								return !this.testFlag
							}
						}, {
							label: '排序',
							value: 'sort',
							type: 'number',
							comp: 'el-input'
						}, {
							label: '立刻购买B类',
							value: 'isRapidBuyB',
							comp: 'el-switch',
							bind: {
								activeValue: 1,
								inactiveValue: 0
							},
							isHideItem: () => {
								return !this.testFlag
							}
						}, {
							label: '限制套数',
							value: 'maxPage',
							type: 'number',
							comp: 'el-input'
						}, {
							label: '累计消费限制',
							value: 'consume',
							type: 'number',
							comp: 'el-input'
						}, {
							label: '玩法说明',
							value: 'note',
							type: 'textarea',
							comp: 'el-input',
							className: 't-form-block'
						}, {
							label: '封面图',
							value: 'coverImg',
							slotName: 'uploadFile'
						}, {
							label: '盒子图片',
							value: 'boxImg',
							slotName: 'uploadFile'
						}, {
							label: '高清图片',
							value: 'hdImg',
							slotName: 'uploadFile'
						}, {
							label: '全家福图片',
							value: 'entireImg',
							slotName: 'uploadFile'
						}, {
							label: '详情图',
							value: 'detailImg',
							slotName: 'uploadFile'
						}, {
							label: '',
							slotName: 'detailsList',
							className: 't-form-block'
						},
					],
					rules: {
						// seriesCode: [
						//   { required: true, message: '请输入系列编号', trigger: 'blur' },
						//   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
						// ],
						seriesName: [{
							required: true,
							message: '请输入系列名称',
							trigger: 'blur'
						}],
						deliverDate: [{
							required: true,
							message: '请输入系列编号',
							trigger: 'blur'
						}],
						price: [{
							required: true,
							message: '请输入价格',
							trigger: 'blur'
						}],
						soldPrice: [{
							required: true,
							message: '请输入折扣价',
							trigger: 'blur'
						}],
						seriesType: [{
							required: true,
							message: '请至少选择一个分类',
							trigger: 'change'
						}],
						shelves: [{
							required: true,
							message: '请至少选择一个状态',
							trigger: 'change'
						}],
						totalPage: [{
							required: true,
							message: '请输入总套数',
							trigger: 'blur'
						}],
						showPage: [{
							required: true,
							message: '请输入展示的套数',
							trigger: 'blur'
						}],
						luckyNum: [{
							required: true,
							message: '请输入连抽次数',
							trigger: 'blur'
						}],
						tipsum: [{
							required: true,
							message: '请输入提示卡使用次数',
							trigger: 'blur'
						}],
						lucencysum: [{
							required: true,
							message: '请输入透视卡使用次数',
							trigger: 'blur'
						}],
						integral: [{
							required: true,
							message: '请输入回收积分数量',
							trigger: 'blur'
						}],
						recycleBlance: [{
							required: true,
							message: '请输入回收鸭币数量',
							trigger: 'blur'
						}],
						maxPage: [{
							required: true,
							message: '请输入限制套数',
							trigger: 'blur'
						}],
					},
					//按钮
					operatorList: [],
					// 相关列表
					listTypeInfo: {
						shelves: this.$store.state.menu.dictList.shelves,
						seriesTypes: this.$store.state.menu.dictList.series_type,
						levelTypes: [{
							key: "A等级",
							value: 17
						}, {
							key: "B等级",
							value: 18
						}, {
							key: "C等级",
							value: 19
						}, ],
					}
				},
				testFlag: false, //测试
			}
		},
		created() {
			//测试人员
			// let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			// if (userInfo.name == '测试') {
			//   this.testFlag = true
			// }
		},
		mounted() {},
		computed: {
			aCount() {
				const listA = this.formOpts.formData.detailsList.filter(item => {
					return item.levelType == 17
				})
				return listA.length
			},
			bCount() {
				const listB = this.formOpts.formData.detailsList.filter(item => {
					return item.levelType == 18
				})
				return listB.length
			},
			cCount() {
				const listC = this.formOpts.formData.detailsList.filter(item => {
					return item.levelType == 19
				})
				return listC.length
			}
		},
		methods: {
			//取消展示套数的修改
			cancel() {
				this.dialogVisible = false
				this.butLoading = false
			},
			// 初始化
			init(id, type) {
				var that = this
				that.isDrawer = true
				that.goodId = id
				if (type) {
					this.formOpts.formData.sort = type[0].sort + 1
				}
				if (id) {
					that.getDetails(id)
				}
				console.log(this.formOpts.formData)
			},
			//关闭弹框
			handleClose() {
				this.isDrawer = false
				this.resetForm()
			},
			//获取详情数据
			getDetails(id) {
				this.$http.get(`/boxSeries/findDetaildsBySeriesId?seriesId=${id}`).then(({
					data: res
				}) => {
					let goodData = res.data
					if (goodData.detailsList == null || goodData.detailsList == undefined || goodData.detailsList == "") {
						goodData.detailsList = []
					}
					this.formOpts.formData = goodData
				})
			},
			// 触发事件
			handleEvent(type, val) {
				switch (type) {
					case 'checkbox':
						console.log(val, type)
						break
					case 'showPage':
						if (!this.goodId) {
							this.formOpts.formData.totalPage = val
						}
						break
					case 'showPrice':
						this.formOpts.formData.integral = Number(val) * 2
						this.formOpts.formData.recycleBlance = Number(val) / 2
				}
			},
			// 新增商品
			addDomain() {
				this.formOpts.formData.detailsList.push({
					ductName: '',
					price: '',
					levelType: '',
					boxImg: '',
				})
			},
			removeDomain(index) {
				this.formOpts.formData.detailsList.splice(index, 1)
			},
			editOkSubmit() {
				this.dialogVisible = false
				let goodData = JSON.stringify(this.formOpts.formData)
				// goodData.json = JSON.stringify(goodData.detailsList)
				delete goodData.detailsList
				// goodData.detailsList = JSON.stringify(goodData.detailsList)
				// console.log(goodData.detailsList, 'json')
				let apiUrl = ''
				if (this.goodId) { //修改
					apiUrl = '/boxSeries/editBoxSeries'
				} else { //新增
					apiUrl = '/boxSeries/addBoxSeries'
				}
				this.$http.post(apiUrl, goodData, {
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(res => {
					this.$message({
						message: this.goodId ? '修改成功' : '新增成功',
						type: 'success'
					})
					this.butLoading = false
					this.$emit('refresh')
					this.handleClose()
				}).catch(err => {
					this.butLoading = false
				})
			},
			// 提交form表单
			submitForm() {
				this.formOpts.ref.validate((valid) => {
					if (valid) {
						this.butLoading = true
						console.log(77, this.formOpts.formData)
						if (!valid) return
						console.log('最终数据', this.formOpts.formData)
						if (this.formOpts.formData.showPage > 10) {
							this.dialogVisible = true
							return
						} else {
							this.editOkSubmit()
						}
					}
				})
			},
			// 重置form表单
			resetForm() {
				this.$data.formOpts.formData = this.$options.data.call(this).formOpts.formData
				this.$nextTick(() => {
					this.formOpts.ref.clearValidate()
				})
			},
		}
	}
</script>
<style lang="less" scoped>
</style>